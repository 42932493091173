<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A student is tasked with creating an electrochemical cell with a voltage of
        <number-value :value="voltage" unit="\text{V}" />
        using
        <stemble-latex content="$1.00\,\text{M}$" />
        <chemical-latex content="Zn(NO3)2" />
        <chemical-latex content="Zn(s)," />
        <stemble-latex content="$1.00\,\text{M}$" />
        <chemical-latex content="Cu(NO3)2," />
        and
        <stemble-latex content="$\ce{Cu(s)}.$" />
        To accomplish this, they will need to dilute one of the metal solutions.
        <b>Hint</b>. Use
        <stemble-latex content="$\text{E}^\circ_\text{cell}=1.10\,\text{V}$" />
        and note that in the Nernst equation using
        <stemble-latex content="$\ln,$" />
        <stemble-latex content="$\text{R}\text{T}/\text{F}=0.0257\,\text{V.}$" />
      </p>

      <p class="mb-n3">a) Which solution will they have to dilute?</p>

      <v-radio-group v-model="inputs.inputSolution" :disabled="!allowEditing" class="pl-8">
        <v-radio
          v-for="option in radioOptions"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <chemical-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        b) Determine the required concentration for the cell where the solution is being diluted to
        achieve the target potential.
      </p>

      <calculation-input
        v-model="inputs.inputMolarity"
        prepend-text="$\text{Molarity: }$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemMcMPL5Q11',
  components: {
    STextarea,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputSolution: null,
        inputMolarity: null,
      },
      radioOptions: [
        {
          text: 'Cu(NO3)2',
          value: 'a',
        },
        {
          text: 'Zn(NO3)2',
          value: 'b',
        },
      ],
    };
  },
  computed: {
    voltage() {
      return this.taskState.getValueBySymbol('voltage').content;
    },
  },
};
</script>
